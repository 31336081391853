import React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent, PostBannerLayout } from '@shapeable/ui';

export default createGatsbyPageComponent('Post', { layout: (props) => <PostBannerLayout {...props} headerProps={{ aspectRatio: { base: 16 / 9 } }} /> });

export const QUERY = graphql`
  query PostQuery($id: ID!) {
    platform {
      post(id: $id) {
        id slug path
        name
        title 
        pretitle
        subtitle
        published
        content { plain text }
        openGraph { title description { plain } image { url url2x thumbnails { card { url url2x } bubble { url url2x } } } }
        intro { plain text }
        outro { text }
        videos {
          id name url
          thumbnail { id url url2x type }
        }
        imageAssets {
          id name image { id url url2x type }
        }
        organisations {
          id name slug path
          logo { id url url2x type }
        }
        people {
          id name slug path
          organisation { id name }
          linkedin
          position
          photo { id url url2x type }
        }
        embeds {
          citations {
            slug url name authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
          }
          pages {
            id name slug path
            openGraph {
              title description { plain }
              image { url url2x type }
            }
          }
          people {
            id name slug path
            organisation { id name }
            position
            photo { id url url2x }
          }
          imageAssets {
            id
            slug
            image {
              id url url2x width height type 
            }
          }
        }
        banners {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height type thumbnails { small { url url2x } }
          }
        }
      }
    }
  }
`;
